@charset "utf-8";

/* wms-detail PO, DO 상단 고정 */
app-wms-inbound-detail,
app-wms-outbound-detail {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 99;
  .detail_page {
    position: relative;
    .inner_detail_connect {
      padding: 130px 0 48px 0;
      .detail_connect_top {
        position: fixed;
        top: 0;
        width: 920px;
        padding-top: 48px;
        background-color: #fff;
        z-index: 99;
      }
      app-wms-inbound-tabs,
      app-wms-outbound-tabs {
        position: fixed;
        top: 73px;
        width: 920px;
        background-color: #fff;
        z-index: 99;
      }

      app-wms-inbound-po-detail,
      app-wms-inbound-gr-detail,
      app-wms-outbound-do-detail,
      app-wms-outbound-gi-detail,
      app-wms-outbound-dc-detail {
        .detail_document {
          padding: 88px 48px 60px;
        }
        .detail_doc_top {
          position: fixed;
          top: 130px;
          width: 824px;
          padding-top: 32px;
          background-color: #fff;
          z-index: 99;
        }
      }
    }
  }
}

.detail_page {
  .detail_connect_top {
    .txt_status {
      height: 24px;
      font-size: 12px;
      font-weight: $font-medium;
      margin-left: 8px;
      padding: 0 8px;
      border-radius: 4px;
      line-height: 24px;
      &.received {
        color: #0f8ce2;
        background: rgba(26, 157, 247, 0.15);
      }
      &.requested {
        color: #03bd8d;
        background: rgba(15, 199, 151, 0.15);
      }
      &.preparing {
        color: #e69800;
        background: rgba(236, 170, 0, 0.15);
      }
      &.fulfilled {
        color: #5c57dd;
        background: rgba(92, 87, 221, 0.15);
      }
      &.reported {
        color: #f73d3f;
        background: rgba(247, 61, 63, 0.15);
      }
      &.canceled {
        color: #6f6f80;
        background: #e9e9ed;
      }
      &.delivered {
        color: #f2751c;
        background: #ffede0;
      }
    }
    .btn_comm {
      &.btn_close {
        margin-left: auto;
      }
    }
  }
  /* 전체 타이틀 */
  .tit_data_detail {
    padding: 32px 0 0 0;
    letter-spacing: 0.2px;
    .tit_count {
      display: inline-block;
      font-size: 14px;
      font-weight: $font-bold;
      background-color: $color-primary;
      margin-left: 4px;
      padding: 0 10px;
      border-radius: 4px;
      color: $color-white;
      vertical-align: top;
      letter-spacing: 0.2px;
    }
  }
  /* 상단 tab menu */
  .list_detail_tab {
    display: flex;
    margin-top: 18px;
    padding: 0 48px;
    border-bottom: 1px solid #d3d3e0;
    li {
      margin-right: 16px;
      cursor: pointer;
      button {
        display: block;
        height: 37px;
        font-size: 16px;
        font-weight: $font-medium;
        background-color: transparent;
        cursor: pointer;
        letter-spacing: 0.16px;
        color: #8b8b9a;
      }
      &:last-child {
        margin-right: 0;
      }
      &.tab_on {
        border-bottom: 1px solid #5c57dd;
        button {
          font-weight: $font-bold;
          color: #5c57dd;
        }
      }
      &:hover {
        border-bottom: 1px solid #5c57dd;
        button {
          font-weight: $font-bold;
          color: #5c57dd;
        }
      }
    }
  }

  /* form */
  .detail_document {
    .detail_doc_top {
      .box_btn {
        button {
          margin-top: 0;
        }
        .btn_request {
          margin-right: 8px;
          &:hover {
            @include btn-purple-line-hover;
          }
          &:disabled {
            @include btn-disabled-border;
          }
        }
      }
    }

    /* po,do 필수값 * 처리 */
    .wrap_temp {
      .wrap_inp_temp {
        .temp_row {
          .lab_temp {
            .temp_essential {
              display: inline-block;
              margin-left: 2px;
              color: $color-primary;
              vertical-align: middle;
            }
          }
          .inp_temp {
            /* 필수값 체크 error */
            &.inp_error {
              /* 셀렉터 */
              .ant-select:not(.ant-select-customize-input) {
                .ant-select-selector {
                  border: 1px solid red;
                }
              }
              /* datepicker */
              .ant-picker {
                border: 1px solid red;
              }
              /* input */
              .ant-input-affix-wrapper {
                border: 1px solid red;
              }
              app-input-number {
                border: 1px solid red;
              }
            }
          }
        }
      }
    }

    /* Document Detail - basic info */
    .detail_doc_basic {
      .wrap_temp {
        .temp_row {
          .temp_essential {
            margin-left: -5px;
          }
        }
      }
      /* input 2개 */
      .wrap_inp_temp {
        display: flex;
        .temp_row {
          width: 406px;
          margin-top: 0;
          &:first-child {
            margin-right: 12px;
          }
        }
      }
    }

    /* Document Detail - shipping paties */
    .detail_doc_shipping {
      /* input 2개 */
      .wrap_inp_temp {
        display: flex;
        .temp_row {
          width: 406px;
          margin-top: 0;
          &:first-child {
            margin-right: 12px;
          }
        }
      }
    }

    /* Document Detail - ConSignor Info */
    .detail_doc_cargo {
      .wrap_inp_temp {
        display: flex;
        .temp_row {
          width: 406px;
          margin-top: 0;
          &:first-child {
            margin-right: 12px;
          }
        }
        .wrap_inp_bundle {
          display: flex;
          .temp_row {
            width: 197px;
          }
        }
      }
    }

    .detail_doc_schedule {
      .txt_sub {
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
        font-weight: $font-medium;
        color: #8b8b9a;
        letter-spacing: 0.16px;
      }
    }
  }

  /* products */
  .inner_container {
    margin: 0 48px 60px;
    .container_top {
      padding-top: 0;
      align-items: center;
      /* 테이블 item add일 경우 타이틀 */
      & + .tit_data_detail {
        font-size: 18px;
        padding: 0 0 16px 0;
        letter-spacing: -0.18px;
        .tit_count {
          font-size: 14px;
        }
      }
    }
    form {
      border: 1px solid #dfdfe5;
      border-radius: 4px;
      .tbl_temp {
        table {
          thead {
            tr {
              th {
                .lab_temp {
                  .temp_essential {
                    display: inline-block;
                    margin-left: 2px;
                    color: $color-primary;
                    vertical-align: middle;
                  }
                }
              }
            }
          }
        }
        /* add 버튼 없는 경우 테이블 간격 없애기 */
        &.add_none {
          table {
            thead {
              tr {
                th {
                  &:first-child {
                    padding-left: 16px;
                  }
                }
              }
            }
            tbody {
              tr {
                td:first-child {
                  padding-left: 16px;
                }
              }
            }
          }
        }
      }

      /* nz 라이브러리 커스텀 */
      .ant-table {
        &.ant-table-bordered {
          .ant-table-container {
            background-color: #f3f3f8;
            /* 테이블 헤더 영역 */
            table {
              border-radius: 0;
              border-top: 0 none;
              .ant-table-thead {
                tr {
                  th {
                    &:first-child {
                      padding-left: 50px;
                    }
                    padding: 0;
                    border-right: 0 none;
                    background-color: #f3f3f8;
                  }
                }
              }
            }

            /* 테이블 컨텐츠 영역 */
            table {
              border-radius: 0;
              &.ant-table-fixed:has(tbody) {
                border-top: 1px solid #dfdfe5;
                .ant-table-tbody {
                  tr {
                    td {
                      padding: 10px 8px 0 0;
                      border: 0 none;
                      &:nth-child(1) {
                        padding-left: 8px;
                      }
                      /* 텍스트 입력시 삭제 버튼 */
                      .btn_delete {
                        position: absolute;
                        left: 12px;
                        top: 18px;
                        width: 28px;
                        height: 28px;
                        font-size: 0;
                        background-color: #dfdfe5;
                        border-radius: 4px;
                        cursor: pointer;
                        &::after,
                        &::before {
                          @include btn-close;
                          height: 12px;
                          margin: -6px 0 0 0;
                          background-color: #8b8b9a;
                        }
                        &::after {
                          transform: rotate(45deg);
                        }
                        &::before {
                          transform: rotate(-45deg);
                        }
                      }
                    }
                    /* 라이브러리 2번째 tr부터 리스트 노출 */
                    &:nth-child(2) {
                      td {
                        padding: 20px 8px 0 0;
                        .btn_delete {
                          top: 27px;
                        }
                        &:nth-child(1) {
                          padding-left: 8px;
                        }
                      }
                    }
                    /* 마지막 tr */
                    &:last-child {
                      td {
                        padding-bottom: 20px;
                      }
                    }
                    &:hover {
                      td {
                        background-color: #f3f3f8;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /* items */
  /* add item+item일 경우 */
  app-wms-po-items-add {
    display: block;
    padding-bottom: 20px;
    .inner_container {
      form {
        .tbl_temp {
          table {
            thead {
              tr {
                th {
                  &:first-child {
                    padding-left: 53px;
                  }
                }
              }
            }
            tbody {
              display: block;
              overflow-y: scroll;
              width: 822px;
              max-height: 297px;
              border: 0 none;
              tr {
                display: table;
                width: 822px;
                &:first-child {
                  border-top: 1px solid #dfdfe5;
                  td {
                    .btn_delete {
                      top: 27px;
                    }
                  }
                }
                td {
                  &:first-child {
                    width: 46px;
                  }
                  width: 168px;
                }
              }
            }
          }
        }
      }
    }
    &:has(+ app-wms-po-items) {
      .inner_container {
        padding: 0;
      }
    }
  }

  /* date picker */
  app-date-picker {
    display: block;
    width: 100%;
    .ant-picker {
      display: block;
      width: 100%;
      height: 45px;
      padding: 0 16px;
      border-radius: 4px;
      line-height: 42px;
    }
  }
  /* Creation Info */
  .detail_creation {
    dl {
      &:nth-of-type(5) {
        dt {
          &::before {
            background: url(../images/ico_dump1.svg) 0 0 no-repeat;
          }
        }
      }
      &:nth-of-type(6) {
        dt {
          &::before {
            background: url(../images/ico_dump2.svg) 0 0 no-repeat;
          }
        }
      }
    }
    /* 파일 다운로드 영역 */
    .list_creation {
      margin-top: 28px;
      border: 1px solid #dfdfe5;
      border-radius: 4px;
      li {
        .file_download {
          padding: 10px 28px;
          margin: 0;
          border: 0 none;
          border-radius: 0;
        }
      }
    }
  }

  /* attached file - dc  */
  .detail_file {
    border-top: 1px solid #dfdfe5;
    padding: 60px 48px;
    .btn_file {
      display: block;
      padding-top: 20px;
    }
  }

  /* inbound - good receipt > disabled 경우 */
  app-wms-inbound-gr-detail,
  app-wms-outbound-gi-detail {
    /* input */
    app-input-text {
      .ant-input-affix-wrapper {
        &.ant-input-affix-wrapper-disabled {
          .ant-input {
            background-color: $color-main;
            color: $color-text2;
            &::placeholder {
              color: $color-text2;
            }
          }
        }
      }
    }
  }
  /* gr 테이블 */
  .inner_detail_connect {
    app-wms-gr-items {
      .ant-table {
        .ant-table-container {
          .ant-table-header {
            table {
              .ant-table-thead {
                tr {
                  th:first-child {
                    padding-left: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
